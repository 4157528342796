import React from "react"
import {Link} from "gatsby"
import "./navigationLinks.scss";

export default function NavigationLinks(){
    return(
        <div className={`navigationLinks`}>
           <Link to="/">Home</Link>
           <Link to="/about">About</Link>
           <Link to="/calendar">Calendar</Link>
           <Link to="/news">News</Link>
        </div>
    )
}
import React from "react"
import {useStaticQuery, graphql} from "gatsby"
import IconButton from "../IconButton/IconButton"
import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import BottomBorder from "../BottomBorder/BottomBorder"
import "./nextEvent.scss"

const NextEvent = () => {

    const data = useStaticQuery(graphql`
    query NextEvent {
        allMarkdownRemark(filter: {frontmatter: {type: {eq: "Calendar"}}},
            sort: {fields: frontmatter___date},
            limit: 1) {
            nodes {
                frontmatter {
                    path
                    remoteurl
                    title
                    date(formatString: "dddd LL")
                    address
                    round
                    featured {
                        publicURL
                    }
                }
            }
        }
      }`).allMarkdownRemark.nodes[0]

      const frontmatter = data.frontmatter
    return(
        <section title="Next Event" className="nextEvent">
            <div className="nextEvent__inner">
                <div className="nextEvent__body">
                <em className="nextEvent__heading">Next Event</em>
                <h3 className="nextEvent__title">
                    {frontmatter.title}
                </h3>
                <p>
                    {frontmatter.address}<br/>
                    {frontmatter.round}<br/>
                    {frontmatter.date}
                </p>

                <IconButton 
                    imgIconFa={faCalendar}
                    linkTarget={frontmatter.remoteurl}
                    linkText="Learn More"
                    ></IconButton>
                </div>
                <img width="300"
                    className="nextEvent__featuredImg" 
                    src={frontmatter.featured.publicURL} 
                    alt="2020 PZM WARSAW FIM SPEEDWAY GRAND PRIX OF POLAND Track"/>
            </div>
            <BottomBorder/>
        </section>
    )
}

export default NextEvent;
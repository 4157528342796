import React, { Component } from "react";
import logo from "../../Images/logo.svg";
import { Link } from "gatsby";
import "./footer.scss";
import SocialLinks from "../SocialLinks/SocialLinks"
import NavigationLinks from "../NavigationLinks/NavigationLinks"
import headerpin from "../../Images/headerpin.svg";
import NextEvent from "../NextEvent/NextEvent";


class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <Link to="/">
            <img src={logo} alt="Team Max" className="header__logo" />
          </Link>
          <div className="footer__bottomNav">
         
          <NavigationLinks></NavigationLinks>
          </div>
          <div className="footer__nextEvent">
            <NextEvent></NextEvent>
          </div>
          <div className="footer__socialHolder">
          <SocialLinks></SocialLinks>
          </div>
          <img src={headerpin} alt="" className="footer__pin" />
      </footer>
    );
  }
}

export default Footer;

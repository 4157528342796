import React, {useState} from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import BackgroundImage from 'gatsby-background-image'
import Img from 'gatsby-image'
import logo from "../../Images/logo.svg";
import headerpin from "../../Images/headerpin.svg";
import Navgation from "../Navigation/Navigation"
import SocialLinks from "../SocialLinks/SocialLinks"
import "./header.scss";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const data = useStaticQuery(graphql`
  query {
    banner: file(relativePath: { eq: "images/banner2.png" }) {
      childImageSharp {
        # Specify a fixed image and fragment.
        # The default width is 400 pixels
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    cclogo: file(relativePath: { eq: "images/CC_logo.png" }) {
      childImageSharp {
        # Specify a fixed image and fragment.
        # The default width is 400 pixels
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`)

    return (
      <BackgroundImage
            Tag="section"
            className={"header"}
            fluid={data.banner.childImageSharp.fluid}
            alt="Gatsby Docs are awesome"
          >
          <div className="header__underlay"></div>
          <Navgation isOpen={isOpen} handleClick={()=>{
                setIsOpen(!isOpen)
          }}/>
          <Link to="/">
            <img src={logo} alt="Team Max" className={`header__logo ${isOpen && "is-open"}`} />
          </Link>
          <img src={headerpin} alt="" className="header__pin" />
          <h1 className={`header__title ${isOpen && "is-open"}`} style={{display:"none"}} >
            <span>
              Welcome to Team Max,<br/> Speedway Revolution
            </span>
          </h1>
          <SocialLinks isOpen={isOpen} />
          <div className={`header__sponsors ${isOpen && "is-open"}`} >
            <a href="https://codeandcreate.co.uk">
              <Img fluid={data.cclogo.childImageSharp.fluid} alt="Sponsored by code and create" />
            </a>
          </div>
      </BackgroundImage>
    );
}

export default Header;

import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "./iconButton.scss"

export default function IconButton({
        linkText = "",
        linkTarget = "",
        imgIconFa ="",
        imgAlt="",
        className=""
    }) {

    return(
        <div className={`iconButton ${className}`}>
            <a className="iconButton__icon" href={linkTarget}>
                <FontAwesomeIcon size="lg" icon={imgIconFa} color={"white"}></FontAwesomeIcon>
            </a>
            <a className="iconButton__link" href={linkTarget}>
                {linkText}
            </a>
        </div>
    )
}
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faFacebook } from '@fortawesome/free-brands-svg-icons'
import "./socialLinks.scss";

export default function SocialLinks({isOpen}) {

    return (
      <div className={`socialLinks ${isOpen && "is-open"}`}>
        <a href="https://twitter.com/max_fricke" className="socialLinks__twitter">
          <FontAwesomeIcon  size={"3x"} icon={faTwitter} color={"white"}></FontAwesomeIcon>
        </a>
        <a href="https://www.facebook.com/Max-Fricke-Racing-467069560024985/" className="socialLinks__facebook">
          <FontAwesomeIcon  size={"3x"} icon={faFacebook} color={"white"}></FontAwesomeIcon>
        </a>
      </div>
    );
}

import React from "react";
import "./sectionHeader.scss";

export default function SectionHeader({
    children, 
    className
}){

    return(
        <h2 className={`sectionHeader ${className}`}>
            {children}
        </h2>
    )
}

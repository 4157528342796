import React from "react";
import Helmet from "react-helmet";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import config from "../../data/SiteConfig";
import "./index.scss";

const Layout = ({children}) => {

  return (
    <div className="layout">
      <Helmet>
        <meta name="description" content={config.siteDescription} />
        <html lang="en" />
      </Helmet>
      <Header></Header>
      {children}
      <Footer></Footer>
    </div>
  );
}

export default Layout